import Vue from 'vue'
import Vuex from 'vuex'
import title from './modules/title'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import nav from './modules/nav'
import locations from './modules/locations'
import types from './modules/types'
import channels from './modules/channels'
import user from './modules/user'
import users from './modules/users'
import vendors from './modules/vendors'
import roles from './modules/roles'
import address from './modules/address'
import reports from './modules/reports'
import amenities from './modules/amenities'
import reservations from './modules/reservations'
import currencies from './modules/currencies'
import dashboards from './modules/dashboards'
import rateManagement from './modules/rateManagement'
import bulkUpload from './modules/bulkUpload'
import googleMaps from './modules/googleMaps'
import timezones from './modules/timezones'
import analytics from './modules/analytics'
import locationEmail from './modules/locationEmail'
import locationEmailSchedule from './modules/locationEmailSchedule'
import guaranteeCard from './modules/guaranteeCard'
import refundRequests from './modules/refundRequests'
import audits from './modules/audits'
import pointOfInterest from './modules/pointOfInterest'
import apiDocumentationUpload from './modules/apiDocumentationUpload'
import leaseTypes from './modules/leaseTypes'

Vue.use(Vuex)

export function createStore () {
  const debug = process.env.NODE_ENV !== 'prod'
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    modules: {
      nav,
      locations,
      types,
      channels,
      user,
      users,
      vendors,
      pointOfInterest,
      roles,
      address,
      reservations,
      amenities,
      currencies,
      reports,
      dashboards,
      rateManagement,
      bulkUpload,
      apiDocumentationUpload,
      googleMaps,
      timezones,
      analytics,
      locationEmail,
      locationEmailSchedule,
      guaranteeCard,
      audits,
      refundRequests,
      leaseTypes,
      //NOTE: !!!DO NOT CHANGE AFTER THIS LINE!!!
      title
    },
    strict: debug,
  })
}
