import _ from 'lodash'

const computed = {
  pagesRange () {
    let arr = [], current, start, end;
    current = parseInt(this.$route.query.page) || 1;
    start = current - 5 > 0 ? current - 5 : 1;
    end = current + 5 <= this.pagesNumber ? current + 5 : this.pagesNumber;
    for(let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  },
  current () {
    return parseInt(this.$route.query.page) || 1
  }
}

const methods = {
  goTo (page, $event) {
    console.log('page');
    if (page <= this.pagesNumber && page > 0) {
      this.$emit('pageChanged', page)
    }
    return
  }
}

export default {
  name: 'pagination',
  props: ['pagesNumber'],
  computed,
  methods
}
