import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: [],
  costs: {},
  comments: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['reservations.getReservations'] ({ commit }, params) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations`, params)).then((res) => {
      commit('GET_ALL_RESERVATIONS', res);
    }).catch((err) => {
      commit('RESERVATIONS_ERROR', err);
    });
  },


  ['reservations.get'] ({ commit, state }, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations/${id}`)).then((reservation) => {
      commit('GET_RESERVATION', reservation);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },

  ['reservations.update'] ({ commit, state }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.put(`reservations/${id}`, data)).then((reservation) => {
      commit('GET_RESERVATION', reservation);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },

  ['reservations.costs'] ({ commit, state }, {id, data}) {
    commit('CLEAR_ERRORS')

    return (http.post(`reservations/${id}/costs`, data)).then((costs) => {
      commit('GET_RESERVATION_COSTS', costs);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },

  ['reservations.getComments'] ({ commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.get(`reservations/${id}/comments`)).then ((res) => {
      commit('GET_RESERVATION_COMMENS', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.newComment'] ({ commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations/${id}/comments`, data)).then ((res) => {
      commit('NEW_RESERVATION_COMMENT', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.updateComment'] ({ commit}, {locId, id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`reservations/${locId}/comments/${id}`, data)).then ((res) => {
      commit('UPDATE_RESERVATION_COMMENT', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.removeComment']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`reservations/${locId}/comments/${id}`)).then((res) => {
      commit('REMOVE_RESERVATION_COMMENT', res);
    })
    .catch((err) => {
      commit('RESERVATIONS_ERROR', err);
    });
  }
}

// mutations
const mutations = {
  GET_RESERVATION (state, res) {
    if (res)
      state.active = res.data;
  },

  GET_ALL_RESERVATIONS (state, res) {
    state.all = res.data;
    _.forEach(state.all.data, (item, index) => {
      state.all.data[index].active_history = _.find(state.all.data[index].history, (item) => item.active);
    });
  },

  GET_RESERVATION_COSTS (state, res) {
    if (res)
      state.costs = res.data;
  },

  GET_RESERVATION_COMMENS (state, res) {
    state.comments = _.isEmpty(res.data) ? [] : res.data;
  },

  NEW_RESERVATION_COMMENT (state, res) {
    state.comments.push(res.data);
  },

  UPDATE_RESERVATION_COMMENT (state, res) {
    let indx = _.findIndex(state.comments, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.comments[indx] = _.assign(state.comments[indx], res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  REMOVE_RESERVATION_COMMENT (state, res) {
    state.comments = state.comments.filter((item) => {
      return item.id !== res.data.id
    });
  },
  
  RESERVATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
