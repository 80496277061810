import dashboardHandler from 'pages/dashboards'
import mainPage from 'pages/main'
import PageNotFound from 'pages/notfound'
import Login from 'pages/login'
import resetPassword from 'pages/resetPassword'
import setPassword from 'pages/setPassword'
import Settings from 'pages/settings'

export default [].concat(
  require('./locations').default,
  require('./rateManagement').default,
  require('./guestManagement').default,
  require('./bookingConditions').default,
  require('./locationInformation').default,
  require('./locationAdministration').default,
  require('./analytics').default,
  require('./locationProfile').default,
  require('./channels').default,
  require('./roles').default,
  require('./reservations').default,
  require('./users').default,
  require('./vendors').default,
  require('./reports').default,
  require('./audits').default,
  require('./guaranteeCard').default,
  require('./refundRequests').default,
  require('./pointOfInterest').default,
  [
    //User settings
    { path: '/settings', name: 'settings', component: Settings},

    //Main and login
    { path: '/login', name: 'login', component: Login, meta: {public: true}},
    { path: '/reset-password', name: 'resetPassword', component: resetPassword
      , meta: {public: true}},
    { path: '/set-password', name: 'setPassword', component: setPassword
      , meta: {public: true}},
    { path: '/home', name: 'home', component: dashboardHandler },
    { path: '/', name: 'main', component: mainPage, meta: {public: true} },
    { path: '*', name: 'notfound', component: PageNotFound
      , meta: {public: true}}
  ]
);
