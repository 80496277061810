import moment from 'moment'
import toastr from 'toastr'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'
import { permits } from 'services'

const directives = {
  hasPermits
}

const computed = {
  channel () {
    console.log('rates changed');
    
    return _.cloneDeep(this.$store.state.locations.activeChannel);
  },

  priceBasis () {
    return _.cloneDeep(this.$store.state.locations.active.daily_or_hourly) || 'hourly';
  },
}

const components = {
  confirm
}

const methods = {
  getRatesOfType (type) {
    let rates, type_id;
    if (this.type == 'parking') {
      rates = this.channel.parkingRates;
      type_id = 'location_parking_type_id';
    } else if (this.type == 'bundle') {
      rates = this.channel.bundleRates;
      type_id = 'location_bundle_id';
    } else {
      rates = this.channel.roomRates;
      type_id = 'location_room_type_id';
    }

    return _.filter(rates, (rate) => type.id == rate[type_id])
  },
  remove (type, rate, force = false) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.$store.dispatch('locations.removeRate', {type, rate, force, channel_id: this.channel.id, location_id: this.locId}).then(() => {
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if (errors.message && errors.message.includes("You don't have permission to delete")) {
            toastr.error(errors.message);
          }
          else if(errors.status_code == 400) {
            this.$refs.confirmDelete.confirm('This rate has reservations in it, are you realy sure?', () => {
              this.remove (type, rate, true);
            })
          } else if (errors) {
            _.forEach(errors, (error) => {
              toastr.error(error[0]);
            })
          }
        }
      })
    });
  },
  trueDays (item) {
    let days = [
      'sunday', 'monday', 'tuesday', 'wednesday',
      'thursday', 'friday', 'saturday'
    ];

    let to_return = [];
    for (let i in days) {
      if (item[days[i]])
        to_return.push(days[i])
    }

    return to_return.join(', ')
  },

  isLocationUser () {
    return !permits.hasPermit('super_admin') && permits.hasPermit('location_user');
  },
}

export default {
  name: "list-view",
  props: ['numberOfDays', 'rateTypes', 'fromDate', 'type'],
  computed,
  methods,
  components,
  directives,
  data () {
    return {
      moment,
      locId: this.$route.params.id
    }
  }
}
