var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c(
      "ul",
      { staticClass: "pagination" },
      [
        _c(
          "li",
          { class: { disabled: _vm.current <= 1, active: _vm.current == 1 } },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    path: _vm.$router.path,
                    query: {
                      page: 1
                    }
                  },
                  "aria-label": "First"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goTo(1)
                  }
                }
              },
              [_vm._v("\n        First\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { class: { disabled: _vm.current <= 1 } },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    path: _vm.$router.path,
                    query: {
                      page: _vm.current - 1 < 1 ? 1 : _vm.current - 1
                    }
                  },
                  "aria-label": "Previous"
                },
                nativeOn: {
                  click: function($event) {
                    _vm.goTo(parseInt(_vm.current))
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("«")])]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.pagesRange, function(page) {
          return _c(
            "li",
            { class: { active: _vm.current == page } },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      path: _vm.$route.path,
                      query: { page: page }
                    }
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.goTo(_vm.$route.query.page)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(page) + "\n      ")]
              )
            ],
            1
          )
        }),
        _vm._v(" "),
        _c(
          "li",
          { class: { disabled: _vm.current >= _vm.pagesNumber } },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    path: _vm.$router.path,
                    query: {
                      page:
                        _vm.current + 1 > _vm.pagesNumber
                          ? _vm.pagesNumber
                          : _vm.current + 1
                    }
                  },
                  "aria-label": "Next"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goTo(_vm.current)
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("»")])]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          {
            class: {
              disabled: _vm.current >= _vm.pagesNumber,
              active: _vm.current == _vm.pagesNumber
            }
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    path: _vm.$router.path,
                    query: {
                      page: _vm.pagesNumber
                    }
                  },
                  "aria-label": "Last"
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goTo(_vm.pagesNumber)
                  }
                }
              },
              [_vm._v("\n        Last\n      ")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-665e07ad", { render: render, staticRenderFns: staticRenderFns })
  }
}