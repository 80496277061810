import moment from 'moment-timezone'
import { tab, tabs } from "components/tabs"
import { funcModal } from 'components/modals'
import { dateFormat, stripTags, currencySymbol } from 'filters'
import { hasPermits } from 'directives'
import partialRefundModal from './partialRefundModal'
import revertPartialRefundModal from './revertPartialRefundModal'
import partialRefundRequestModal from './partialRefundRequestModal'
import guaranteeCard from './guaranteeCard'
import toastr from 'toastr'
import confirm from 'components/modals/confirm'
import extendReservationModal from 'components/modals/extendReservationModal'
import cancelReservationModal from './cancelReservationModal'
import { permits, user} from 'services'

const components = {
  funcModal,
  tab, tabs,
  partialRefundModal,
  partialRefundRequestModal,
  revertPartialRefundModal,
  guaranteeCard,
  confirm,
  cancelReservationModal,
  extendReservationModal
}

const filters = {
  dateFormat,
  stripTags,
  currencySymbol,
}

const directives = {
  hasPermits
}

const methods = {
  open (checkin_out = null, item_to_be_checkedin_out = null) {
    this.checkin_out = checkin_out;
    this.item_to_be_checkedin_out = item_to_be_checkedin_out;
    this.card_details = null;
    this.$nextTick(() => {
      this.$refs.modal.open();
    });
  },

  openPartialRefundModal () {
    if (this.current.dates.some(e=>e.check_in)) {
      this.$refs.confirmWarning.confirm('Are you sure? This reservation has been validated by the location.', () => {
        this.$refs.modal.close();
        this.$refs.partialRefundModal.open(this.current);
      });
    } else {
      this.$refs.modal.close();
      this.$refs.partialRefundModal.open(this.current);
      }
  },

  openRevertPartialRefundModal () {
    this.$refs.modal.close();
    this.$refs.revertPartialRefundModal.open(this.local_reservation, this.activeHistory);
  },

  openCancelReservationModal () {
    this.$refs.modal.close();
    this.$refs.cancelReservationModal.open(this.reservation, this.activeHistory);
  },

  openPartialRefundRequestModal () {

    if (!this.isReservationFinished) {
      this.$refs.confirmDialog.confirm("Once a refund is accepted, the reservation freezes and does not accept any more changes or refunds. It is recommended to submit a refund approval after the reservation has ended", () => {
        this.$refs.modal.close();
        this.$refs.partialRefundRequestModal.open(this.current.available_for_partial_refund || 0, this.reservation.id);
      }, null, "Continue");
    } else {
      this.$refs.modal.close();
      this.$refs.partialRefundRequestModal.open(this.current.available_for_partial_refund || 0, this.reservation.id);
    }

  },

  onCloseRefundModal () {
    this.local_reservation = Object.assign({}, this.local_reservation, this.$store.state.locations.active_reservation);
    this.open();
  },

  onCloseCancelModal () {
    this.local_reservation = Object.assign({}, this.local_reservation, this.$store.state.reservation);
  },

  openCardModal () {
    this.$refs.modal.close();
    this.$refs.cardModal.open(this.reservation.id, this.current.location.id);
  },

  onCloseCardModal () {
    this.open();
  },

  onCloseCheckinModal () {
    if (!_.isEmpty(this.$store.state.channels.active_reservation)) {
      this.$store.dispatch('channels.update_no_show_reseration', {
        no_show: this.no_show
      })
    }

    if (!_.isEmpty(this.$store.state.locations.active_reservation)) {
      this.$store.dispatch('locations.update_no_show_reseration', {
        no_show: this.no_show
      })
    }

    if (!_.isEmpty(this.$store.state.dashboards.reservation)) {
      this.$store.dispatch('dashboards.update_no_show_reseration', {
        no_show: this.no_show
      })
    }

  },

  checkinCheckout () {
    if (!this.checkin_out) return;
    let dispatchAction = this.checkin_out == 'check-in' ? 'dashboards.checkInReservation':'dashboards.checkOutReservation';
    if (this.checkin_out == 'check-out') {
      let item = this.item_to_be_checkedin_out;
      let to_date = item.to_date;
      let to = moment(to_date).add(3, 'hours').tz(this.location.timezone.code, true);
      let now = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.location.timezone.code);
  
      if (!item.check_out && now.isAfter(to) && this.location.extend_reservation_when_check_out) {
  
        this.prepareData(item);
  
        this.$store.dispatch('reservations.costs', {id: item.history.reservation.id, data: this.form}).then(() => {
  
          let errors = _.cloneDeep(this.$store.state.reservations.errors);
    
          if (errors) {
            toastr.error(errors.message);
            this.errors = errors.errors;
  
          } else {
            this.costs = _.cloneDeep(this.$store.state.reservations.costs);
            if (this.costs) {
              if (this.costsActiveHistory.due_at_location_total > 0) {
                this.amount_to_pay = (this.costs.reservation.due_at_location - this.costsActiveHistory.due_at_location_total).toFixed(2);
              } else {
                this.amount_to_pay = (this.costs.reservation.grand_total - this.costsActiveHistory.grand_total).toFixed(2);
              }
            }
        
            var duration = moment.duration(now.diff(moment(to_date).tz(this.location.timezone.code, true)));
            let hours = duration._data.days > 0 ? duration._data.days * 24 + duration._data.hours : duration._data.hours;
            let diff = hours + ':' + (duration._data.minutes < 10 ? '0' + duration._data.minutes : duration._data.minutes);
            if (this.amount_to_pay > 0) {
              let currency_code = this.location.currency ? currencySymbol(this.location.currency.code) : "Na";
              this.$refs.extendReservationModal.open(item, this.amount_to_pay + '' + currency_code, diff);
            } else {
              this.dispatchCheckinCheckout(dispatchAction);
            }
          }
        });
  
      } else {
        this.dispatchCheckinCheckout(dispatchAction);
      }
    } else {
      this.dispatchCheckinCheckout(dispatchAction);
    }
  },

  dispatchCheckinCheckout (dispatchAction, close = true) {
    this.$store.dispatch(dispatchAction, {
      id: this.item_to_be_checkedin_out.id, location_id: this.location.id
    }).then(() => {
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        let action = this.checkin_out == 'check-in' ? 'checked-in': 'checked-out';
        let prop = this.checkin_out == 'check-in' ? 'check_in' : 'check_out';
        let status = this.item_to_be_checkedin_out[prop] ? `un${action}` : action;
        let messsage = `Reservation has been ${status} successfully.`
        toastr.success(messsage);
        if (close) {
          this.$refs.modal.close();
        }
      }
    });
  },

  onCloseModal() {
    this.$emit('close');
  },

  updateProcessing(processing) {
    this.checkout_processing = processing;
  },

  updateReservation(type, item) {
    let dispatchAction = this.checkin_out == 'check-in' ? 'dashboards.checkInReservation':'dashboards.checkOutReservation';

    if (type == 'charge' && !this.location.block_extending_reservations) {
      this.errors = {}
      this.costs = null;

      this.prepareData(item);

    this.$store.dispatch('reservations.update', {id: item.history.reservation.id, data: this.form}).then(() => {
      let errors = _.cloneDeep(this.$store.state.reservations.errors);

      if (errors) {
        toastr.error(errors.message);
        this.errors = errors.errors;
      } else {
        let reservation = _.cloneDeep(this.$store.state.reservations.active);
        let active_history = _.find(reservation.history, h => h.active);
        _.forEach(active_history.dates, (date) => {
          if (date.type_type != 'bundle' && date.type_type == item.type_type && date.from_date == item.from_date) {
            this.item_to_be_checkedin_out = date;
            this.dispatchCheckinCheckout(dispatchAction, false);
          }
        });
        toastr.success("Data has been saved");
        this.$emit("reload-list");
      }
    })} else if ((type == 'without_charge' || this.location.block_extending_reservations) && type != 'dont_checkout') {
      this.dispatchCheckinCheckout(dispatchAction);
    } else {
      this.onCloseModal();
      this.$refs.modal.close();
    }
    this.checkout_processing = false;
  },

  prepareData(item) {

    let now = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.location.timezone.code);

    this.form = {
      reservation_number: item.history.reservation.reservation_number,
      location_id: this.location.id,
      email: item.history.email,
      phone: item.history.phone,
      reserved_for: item.history.reserved_for,
      state: item.history.state,
      items: [],
      coupon_discount: item.history.coupon_discount > 0 ? item.history.coupon_discount.toFixed(2): null,
      extend_when_checkout: true
    }
    _.forEach(item.history.extra_fields, (field) => {
      this.form[field.name] = field.value;
    });
      
    _.forEach(item.history.dates, (date) => {
      if (date.type_type == 'bundle') {
        this.form.bundle_id = date.type_id;
        return;
      }

      this.form.items.push({
        id: date.id,
        from_date: date.from_date,
        to_date: date.id == item.id? now.format('YYYY-MM-DD HH:mm:ss') : date.to_date,
        type: date.type_type,
        number_of_spots: date.type_type == 'parking' ? date.number_of_units : null,
        number_of_rooms: date.type_type == 'room' ? date.number_of_units : null,
        type_id: date.type_id,
        guest_names: date.type_type == 'room' ? date.guest_names : null,
        guests_in_rooms: date.type_type == 'room' ? date.number_of_guests : null,
      });
    });

    this.form.items = _.map(this.form.items, (date) => {
      if (date.type == 'room') {
        date.guest_names = _.slice(date.guest_names, 0, Number(date.number_of_rooms));
        date.guests_in_rooms = _.slice(date.guests_in_rooms, 0, Number(date.number_of_rooms));
      }

      return date;
    });
  },

  cancelReservation () {

    if (this.isReservationStarted(false) || this.local_reservation.history_id_when_begin) {
      if (!this.isAdmin) {
        toastr.error("The reservation is started, just super admin can cancel it.");
        return;
      }
      this.openCancelReservationModal();
      return;
    }
  
    let confirmMsg = "Are you sure?";
    if (this.current.dates.some(e=>e.check_in)) {
      confirmMsg="Are you sure? This reservation has been validated by the location.";
    }
  
    this.$refs.confirmDialog.confirm(confirmMsg, () => {

      this.$store.dispatch('dashboards.cancelReservation', {
        channel_id: this.reservation.channel_id,
        reservation_id: this.reservation.id,
        is_full_refund: false
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.dashboards.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let messsage = `Reservation has been cancelled successfully.`
          toastr.success(messsage);
          this.$refs.modal.close();
        }
      })
    })
  },

  disputeReservation () {

    let confirmMsg = "Are you sure you want to dispute this reservation?";
    this.$refs.confirmDialog.confirm(confirmMsg, () => {

      this.$store.dispatch('locations.disputeReservation', {
        lId: this.location.id,
        id: this.local_reservation.id,
        amount: 15
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.locations.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let messsage = `Reservation has been disputed successfully.`
          toastr.success(messsage);
          this.$refs.modal.close();
        }
      })
    })
  },

  revertCancelReservation () {
    this.$refs.confirmDialog.confirm("Are you sure to revert cancel for this reservation?", () => {
      this.$store.dispatch('locations.revertCancel', {
        lId: this.location.id, id: this.local_reservation.id,
      }).then(() => {
        let errors = _.cloneDeep(this.$store.state.locations.errors);
        if (errors) {
          toastr.error(errors.message);
        } else {
          let messsage = `Reservation has been revert cancel successfully.`
          toastr.success(messsage);
          this.$refs.modal.close();
        }
      })
    })
  },

  isReservationStarted (doSubtract24h=true) {

    let startDate = null;

    for (let i in this.historyDetails.parking_dates) {
      let date = moment(this.historyDetails.parking_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      if (startDate == null || date.isAfter(startDate)) {
        startDate = date;
      }
    }

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].from_date, ['YYYY-MM-DD HH:mm:ss']);
      if (startDate == null || date.isAfter(startDate)) {
        startDate = date;
      }
    }
    
    startDate = startDate.tz(this.activeHistory.location.timezone.code, true);
    let today = moment(moment(), ['YYYY-MM-DD HH:mm:ss']).tz(this.activeHistory.location.timezone.code);
    if (doSubtract24h) {
      today = today.subtract('hours',24)
    }
    return today.isAfter(startDate)
  },  

  currencyCode(location) {
    return location.currency ? currencySymbol(location.currency.code) : "Na";
  },

  toggleShow (item) {
    this.$store.dispatch('dashboards.showReservation', {
      'id': item.id, 'from': 'reservation'
    }).then(() => {
      let errors = _.cloneDeep(this.$store.state.dashboards.errors);
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.no_show = this.noShowReservation.no_show;
        this.onCloseCheckinModal()
      }
    });
  },

  writeComment () {
    this.typing_comment = true;
    setTimeout(() => {
      this.$refs.commentEditor.focus();
    }, 100)

    this.cancelCommentEditors();
  },

  cancelComment () {
    this.new_comment = "";
    this.typing_comment = false;
  },

  editComment(commentId, index) {
    this.comments[index].edit_value = this.comments[index].comment;
    this.comments[index].editing = true;
    this.cancelCommentEditors(index);
    this.cancelComment(false);

    setTimeout(() => {
      this.$refs[`commentEditor${commentId}`][0].focus();
    }, 100)
  },

  cancelCommentEditors(index = -1) {
    for (let i = 0; i < this.comments.length; i++) {
      if (i != index || index == -1) {
        this.cancelEdit(this.comments.id,i);
      }
    }
  },

  deleteComment(commentId, index) {
    
    this.$refs.confirmWarning.confirm('Are you sure?', () => {
      this.processing = true;
      this.$store.dispatch('reservations.removeComment', {
        resId: this.local_reservation.id,
        id: commentId
      }).then(() => {
        this.processing = false;
        let errors =  this.$store.state.reservations.errors;
        if (errors) {
          if (errors.errors) {
            this.errors = _.cloneDeep(errors.errors);  
          }
          toastr.error(errors.message);
        } else {
          toastr.success('Comment removed successfully');
        }
      });
    })
  },

  updateComment(commentId, index) {
    if (stripTags(this.comments[index].edit_value)  == "") {
      console.log("empty update");
      return;
    }

    this.errors = {};
    this.processing = true;
    this.$store.dispatch('reservations.updateComment', {
      resId: this.local_reservation.id,
      id: commentId,
      data: {
        comment: this.comments[index].edit_value
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.reservations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been updated successfully');
        this.comments[index].editing = false;
        this.comments[index].comment = this.comments[index].edit_value;
      }
    });
  },

  cancelEdit(commentId, index) {
    this.comments[index].editing = false;
  },

  changeComment(newValue) {
    this.new_comment = newValue;
  },

  addComment () {

    if (stripTags(this.new_comment) == "" ) {
      return;
    }

    this.errors = {};
    this.processing = true;
    this.$store.dispatch('reservations.newComment', {
      id: this.local_reservation.id,
      data: {
        comment: this.new_comment
      }
    }).then(() => {
      this.processing = false;
      let errors =  this.$store.state.reservations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);  
        }
        toastr.error(errors.message);
      } else {
        toastr.success('data has been add successfully');
        this.new_comment = "";
        this.typing_comment = false;
      }
    });
  },
}

const computed = {
  current () {
    console.log('!_isEmpty(this.local_reservation)', !_.isEmpty(this.local_reservation),this.local_reservation );
    
    return !_.isEmpty(this.local_reservation) ? this.local_reservation.history.filter((item) => item.active)[0] : null
  },
  history () {
    return !_.isEmpty(this.local_reservation) ? this.local_reservation.history : [];
  },
  activeHistory () {
    return !_.isEmpty(this.local_reservation) ? this.local_reservation.history.find((item) => item.active) : [];
  },

  partialRefundRequest () {
    return !_.isEmpty(this.local_reservation) ? this.local_reservation.partial_refund_request : null;
  },
  
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },

  checkinOutButtonName () {
    if (!this.checkin_out) return;
    let item_property = this.checkin_out == 'check-in' ? 'check_in' : 'check_out';
    return (this.item_to_be_checkedin_out[item_property] ? `un${this.checkin_out}` : this.checkin_out);
  },

  historyDetails () {
    let current = _.clone(this.current);
    current.room_type = null;
    current.parking_type = null;
    current.parking_dates = [];
    current.room_dates = [];

    for (let i in current.dates) {
      if (current.dates[i].type_type == 'room') {
        current.room_dates.push({
          from_date: current.dates[i].from_date,
          to_date: current.dates[i].to_date,
          check_in: current.dates[i].check_in,
          check_in_time: current.dates[i].check_in_time,
        });

        current.room_type = current.dates[i].type.name;

        current.number_of_guests = current.dates[i].number_of_guests;
        current.number_of_rooms = current.dates[i].number_of_units;
        current.guest_names = current.dates[i].guest_names;
      }

      if (current.dates[i].type_type == 'parking') {
        current.parking_dates.push({
          from_date: current.dates[i].from_date,
          to_date: current.dates[i].to_date,
          check_in: current.dates[i].check_in,
          check_in_time: current.dates[i].check_in_time,
        });
        current.parking_type = current.dates[i].type.name;
        current.number_of_spots = current.dates[i].number_of_units;
      }
    }

    return current;
  },

  credit_card_max_view_date () {
    let max_date = null;

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (max_date == null || date.isAfter(max_date)) {
        max_date = date;
      }
    }
    
    return max_date.add('week', 1)
  },

  isReservationFinished () {

    let finishDate = null;

    for (let i in this.historyDetails.parking_dates) {
      let date = moment(this.historyDetails.parking_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (finishDate == null || date.isAfter(finishDate)) {
        finishDate = date;
      }
    }

    for (let i in this.historyDetails.room_dates) {
      let date = moment(this.historyDetails.room_dates[i].to_date, ['YYYY-MM-DD HH:mm:ss']);
      if (finishDate == null || date.isAfter(finishDate)) {
        finishDate = date;
      }
    }
    
    let today = moment(moment(), ['YYYY-MM-DD HH:mm:ss']);
    return today.isAfter(finishDate)
  },

  timezone_name () {
  
    if (this.user && this.user.type != 'ims' && !permits.hasPermit('super_admin')) {
      return '';
    }
    return this.activeHistory.location.timezone.name;
  },
  isSuperAdmin () {
    return permits.hasPermit('super_admin');
  },

  isAdmin () {
    return this.user && this.user.type == 'ims';
  },

  noShowComments () {
    return this.$store.state.reservations.comments;
  },
  
  noShowReservation () {
    return  _.cloneDeep(this.$store.state.dashboards.reservation)
  },

  costsActiveHistory() {
    return this.costs ? _.find(this.costs.old_reservation.history, h => h.active) : null;
  },
}

const watch = {
  reservation (n, o) {
    this.no_show = n.no_show;
    this.local_reservation = n;
    this.$store.dispatch('reservations.getComments', n.id);
  },

  noShowComments (comments) {
    try {
      this.comments = comments.map((item) => ({
        id: item.id,
        comment: item.comment,
        time: item.updated_at,
        user_name: item.user.name,
        user_id: item.user.id,
        editing: false,
        edit_value: item.comment
      }))
    } catch (e) {
      window.console.error(e)
    }
  }
}

export default {
  name: "reservation-details-modal",
  props: ['reservation', 'type'],
  watch,
  components,
  filters,
  methods,
  computed,
  directives,
  data () {
    return {
      local_reservation: this.reservation,
      checkin_out: null,
      item_to_be_checkedin_out: null,
      user: null,
      typing_comment: false,
      processing: false,
      new_comment: "",
      comments: [],
      no_show: false,
      checkout_processing: false,
      amount_to_pay: 0,
      form: {
        reservation_number: null,
        extra_fields: [],
        items: []
      },
      costs: null,
    }
  },
  mounted () {
    this.user = user.getUser();
    this.comments = [];
  }
}
