import toastr from 'toastr'
import layout from 'layouts/default'
import generalTab from './generalTab'
import commissions from './commissions'
import coupons from './coupons'
import fees from './fees'
import cancellationPolicy from './cancellationPolicy'
import extraFields from './extraFields'
import { hasPermits } from 'directives'
// import './details.scss'

const directives = {
  hasPermits
}

const components = {
  layout,
  generalTab,
  commissions,
  coupons,
  fees,
  cancellationPolicy,
  extraFields
}

const computed = {
  location () {
    return _.cloneDeep(this.$store.state.locations.active);
  },
  // channels() {
  //   return _.cloneDeep(this.$store.state.locations.active.channels);
  // },
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },
}

const watch = {
  location () {
    this.form = _.assign(this.form, _.cloneDeep(this.location));
    try {
      this.form.channels = this.location.channels.map((item) => ({
        id: item.channel.id,
        name: item.channel.name,
        domain: item.channel.domain,
        bundle_commission: item.bundle_commission,
        parking_commission_type: item.parking_commission_type,
        parking_commission: item.parking_commission,
        room_commission: item.room_commission,
        accept_coupon: item.accept_coupon,
        coupon_limit: item.coupon_limit,
        coupon_limit_type: item.coupon_limit_type
      }))
    } catch (e) {}
  },

  ['form.credit_card_required'] (n, o) {
    this.checkDisabled(n, o);
  },

  ['form.room_due_at_location'] (n, o) {
    this.checkDisabled(n, o);
  }
}

const methods = {
  submitEditLocation () {
    this.errors = {}
    this.submit_loading = true;

    this.form.parking_due_at_location = this.form.parking_due_at_location ? 1 : 0;
    this.form.room_due_at_location = this.form.room_due_at_location ? 1 : 0;
    this.form.printed_receipt = this.form.printed_receipt ? 1 : 0;
    this.$store.dispatch('locations.edit', {id: this.$route.params.id, data: this.form}).then(() => {

      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
        this.submit_loading = false;
      } else {
        this.$store.dispatch('locations.getChannels', this.$route.params.id)
        .then(() => {
          toastr.success('Data has been saved');
          this.submit_loading = false;
        })
      }
    })
  },
  displayErrors (errors) {
    this.errors = _.cloneDeep(errors);
  },
  checkDisabled (n, o) {
    if (n == o) return  this.disabled = false;
    if (this.form.credit_card_required && !this.form.room_due_at_location) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
}

export default {
  name: "booking-conditions",
  components,
  computed,
  watch,
  methods,
  directives,
  data () {
    return {
      locId: this.$route.params.id,
      loading: false,
      submit_loading: false,
      disabled: false,
      errors: {},
      form: {
        tax_type: "gross",
        parking_due_at_location: false,
        room_due_at_location: false,
        credit_card_required: false,
        printed_receipt: false,
        daily_or_hourly: 'hourly',
        hours_before_reservation:0,
        hours_before_room_reservation: 0,
        channels: []
      }
    }
  }, created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('locations.get', this.$route.params.id),
      this.$store.dispatch('locations.getChannels', this.$route.params.id),
      this.$store.dispatch('locations.getFees', this.$route.params.id),
      this.$store.dispatch('locations.cancellationPolicies', this.$route.params.id),
      this.$store.dispatch('locations.extraFields', this.$route.params.id),
      this.$store.dispatch('currencies.getAll'),
    ]).then(() => {
      this.loading = false;
      try {
        this.form.channels = this.location.channels.map((item) => ({
          id: item.channel.id,
          name: item.channel.name,
          domain: item.channel.domain,
          parking_commission_type: item.parking_commission_type,
          parking_commission: item.parking_commission,
          room_commission: item.room_commission,
          bundle_commission: item.bundle_commission,
          accept_coupon: item.accept_coupon,
          coupon_limit: item.coupon_limit,
          coupon_limit_type: item.coupon_limit_type
        }))
      } catch (e) {}
    });
  }
}
